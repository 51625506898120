export const subjects = [
  "Pick One...",
  "Training",
  "Coaching",
  "Course Question",
  "Business Proposition",
  "Film Question",
  "Other",
];

export default {
  subjects
};